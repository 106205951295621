import { Component, Input } from '@angular/core';

@Component({
  selector: 'terms',
  templateUrl: 'terms.template.html',
  styleUrls: ['terms.style.sass'],
})
export class TermsComponent {
  @Input() public tab: string = 'terms';
}
