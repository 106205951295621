/* tslint:disable:variable-name */
export class Video {
  public id: number;
  public slug: string;
  public title: string;
  public description: string;
  public video_id: string;
  public video_categories: string[];
  public sentiments: string[];
  public tags: string;
  public price: string[];
  public date_created: string;
  public price_licence1: number;
  public price_licence2: number;
  public price_licence3: number;
}
