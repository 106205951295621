import { Component, OnInit }     from '@angular/core';
import { Router }                from '@angular/router';
import { AuthService }           from '../../common/services/auth.service';
import { BasketService }         from '../../common/services/basket.service';

declare var Stripe: any;
@Component({
  selector: 'user-bar',
  templateUrl: 'user-bar.template.html',
  styleUrls: ['user-bar.style.sass'],
})
export class UserBarComponent implements OnInit {
  public stripe: any;
  public firstname: string;
  public username: string;
  public email: string;
  public password: string;
  public passwordConfirm: string;
  public currentUser: string;

  public terms: boolean = false;
  public formErrors: string = '';

  public userList: string;
  public selectedOrderUser: number;

  // basket need a default empty string because im using .length in template.
  public basket: any = {};
  public basketLoading: boolean = false;
  public basketDetails: boolean = false;
  public basketTotal: number = 0;
  public basketStripeId: string;
  public order: string;
  public notes: string;

  public videoUsage: boolean = false;
  public loginError: string;
  public registerError: any = { email: null, password: null };
  public forgotMessage: string;

  public modalControl: any = { login: false, register: false, forgot: false };

  public router: Router;
  constructor(
    private userService: AuthService,
    private basketService: BasketService,
    public $router: Router) {
    this.router = $router;
    this.stripe = Stripe('pk_test_51GqFWMFWId4NQqJQOMPnh2LqqOFBEQpg4L0EOsdca5ohIonoSZlREgZDh6NRilEV9KQwRpnrSSnt3aUqC3ySBL6100ihZ5jOkP');

    // Subscribe for when the basket get updated
    basketService.basketUpdated$.subscribe(response =>
      basketService.getMyBasketProducts().subscribe(
          (response) => {
            this.order = '';
            this.basket = response;
            this.updateBasket();
          },
      ));
  }

  public ngOnInit() {
    this.userService.getUser().subscribe(
      response => this.currentUser = response,
    );

    this.getBasket();
  }

  public getBasket() {
    this.basketLoading = true;
    this.basketService.getMyBasketProducts().subscribe(
      (response) => {
        this.basket = response;
        this.updateBasket();
      },
    );
  }

  public updateBasket() {
    this.basketTotal = 0;
    this.basket.forEach((product) => {
      if (product.attributes[0].value === '1') {
        this.basketTotal += product.product.price_licence1;
      }
      if (product.attributes[0].value === '2') {
        this.basketTotal += product.product.price_licence2;
      }
      if (product.attributes[0].value === '3') {
        this.basketTotal += product.product.price_licence3;
      }
      this.basketTotal += Math.round((this.basketTotal * 0.035) * 100) / 100;
    });

    this.basketService.getMyBasket().subscribe(
      (response) => {
        this.basketService.getStripeSession(response.json().id).subscribe((response) => {
          this.basketStripeId = response.id;
        });
        this.basketLoading = false;
      },
    );
  }

  public toggleBasketDetails() {
    this.basketDetails = !this.basketDetails;
  }

  public toggleVideoUsage() {
    this.videoUsage = !this.videoUsage;
  }

  public toggleModal(modal: string) {
    this.modalControl.login = false;
    this.modalControl.register = false;
    this.modalControl.forgot = false;
    this.modalControl[modal] = true;
  }

  public submitCheckout() {
    this.basketLoading = true;
    this.formErrors = '';
    if (this.notes === '' || this.notes === undefined) {
      this.formErrors += '<li>The video usage field is required.</li>';
      this.basketLoading = false;
      return 0;
    }
    if (this.terms === false) {
      this.formErrors += '<li>You need to accept the Terms & Conditions</li>';
      this.basketLoading = false;
      return 0;
    }
    return this.basketService.checkout(this.selectedOrderUser, this.notes).subscribe(
      (response) => {
        // Reset the basket counter
        this.basket = JSON.parse('[]');
        this.order = response;
        this.stripe.redirectToCheckout({
          sessionId: this.basketStripeId,
        }).then((result) => {
          console.log(result);
        });
      },
    );

  }

  public submitLogin() {
    return this.userService.loginAndRegister('login', '', this.username, this.password).subscribe(
        (response) => {
          this.currentUser = response;
          this.getBasket();
        },
        error => this.loginError = 'Invalid username or password.',
    );
  }

  public submitForgotPassword() {
    return this.userService.forgotPassword(this.email).subscribe(
      response => this.forgotMessage =
        'We\'ve emailed you instructions for resetting your password, if an account exists with ' +
        'the email you\'ve entered. You should receive them shortly.',
    );
  }

  public submitLogout() {
    return this.userService.logout().subscribe(
      (response) => {
        this.currentUser = null;
        this.modalControl.login = false;
        this.modalControl.register = false;
      },
      error => this.loginError = null,
    );
  }

  public submitRegister() {
    if (this.password === this.passwordConfirm) {
      return this.userService.loginAndRegister(
          'register', this.firstname, this.username, this.password).subscribe(
        (response) => {
          this.currentUser = response;
          this.submitLogin();
        },
        (error) => {
          this.registerError.email = error.json().email;
          this.registerError.password = error.json().password;
        },
      );
    }
    this.registerError.password = 'Passwords doesn\'t match';
  }

  public removeFromCart(id) {
    this.basketLoading = true;
    return this.basketService.removeItemFromCart(id).subscribe(
      (response) => {
        this.getBasket();
      },
    );
  }
}
