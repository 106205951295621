import { Component, OnInit }              from '@angular/core';
import { DomSanitizer, SafeResourceUrl }  from '@angular/platform-browser';
import { ActivatedRoute }          from '@angular/router';

import { AuthService }                    from '../../common/services/auth.service';
import { BasketService }                  from '../../common/services/basket.service';
import { SearchService }                  from '../search/search.service';
import { Video }                          from './video.model';
import { VideoService }                   from './video.service';

@Component({
  selector: 'viral-video',
  templateUrl: 'video.template.html',
  styleUrls: ['video.style.sass'],
})
export class VideoComponent implements OnInit {
  public video: Video;
  public videoUrl: string;
  public basket: string;
  public iframeUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl('about:blank');
  public id: number;
  public playing: boolean = false;
  public selectLicence: boolean = false;
  public activeTab: any = { synopsis: true, related: false };
  public relatedVideos: Video[] = [];
  public requestText = 'Loading...';
  public modalControl: any = { comparisonTable: false };
  public currentUser: string;
  constructor(private videoService: VideoService,
              private userService: AuthService,
              private myVaultService: BasketService,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private searchService: SearchService) {}

  public ngOnInit() {
    this.userService.getUser().subscribe(
      response => this.currentUser = response,
    );

    this.basket = '';
    this.route.params.subscribe((params) => {
      this.id = +params.id;
      this.loadVideo(this.id);
    });
  }

  // Method for switching tabs in video page
  public switchTab(tab: string) {
    this.activeTab.synopsis = false;
    this.activeTab.related = false;
    this.activeTab[tab] = true;
  }

  // Method for loading the iframe into the DOM, toggle the iframe class and
  // changing the iframe url with the video from kaltura.
  public toggleVideo() {
    if (this.video) {
      this.playing = !this.playing;
      if (this.playing) {
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
      } else {
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('about:blank');
      }
    }
  }
  // Method for ajax request to get video informations
  public loadVideo(id: number) {
    this.playing = false;
    this.videoService.getVideo(id).subscribe(
      (video) => {
        this.video = video;
        this.requestText = 'Add to cart';
        this.videoUrl = `https://cdnapisec.kaltura.com/p/2203941/sp/220394100/
embedIframeJs/uiconf_id/37655381/partner_id/2203941?
iframeembed=true&playerId=kaltura_player_1485515039&
entry_id=${video.video_id}&flashvars[streamerType]=auto`;
        const filters = [
          `video_categories_exact:${this.video.video_categories[0]}`,
          `-django_id:${String(this.video.id)}`,
        ];

        this.searchService.searchVideo('', 1, filters, 1).subscribe(
          videos => this.relatedVideos = videos.products,
        );
      },
    );
  }

  public licenceBox() {
    this.selectLicence = !this.selectLicence;
  }

  public addToBasket(id: string, licence: number) {
    this.requestText = 'Please wait...';
    this.selectLicence = false;
    this.myVaultService.addToBasket(id, licence).subscribe(
      (response) => {
        this.basket = response;
        this.requestText = 'Add to cart';
      },
    );
  }

  public toggleModal(modal: string) {
    this.modalControl.comparisonTable = false;

    this.modalControl[modal] = true;
  }

  public toggleComparisonTable() {
    this.modalControl.comparisonTable = !this.modalControl.comparisonTable;
  }

  public closeComparisonTable() {
    this.modalControl.comparisonTable = false;
  }
}
