import { Component, OnInit }  from '@angular/core';
import { Router }             from '@angular/router';

import { SearchService }      from '../search/search.service';
import { Video }              from '../video/video.model';
import { VideoService }       from '../video/video.service';

import { environment }        from '../../environments/environment';

@Component({
  selector: 'discover',
  templateUrl: 'discover.template.html',
  styleUrls: ['discover.style.sass'],
})
export class DiscoverComponent implements OnInit {
  public trendingVideos: Video;
  public deployUrl: string;

  constructor(
      private router: Router,
      private videoService: VideoService,
      private searchService: SearchService,
  ) {}

  public ngOnInit() {
    this.loadTrending();
    this.deployUrl = environment.deployUrl;
  }

  public browseCategory(category: string) {
    this.searchService.discoverFilter(category);
    this.router.navigate(['/search', '1', '']);
  }

  /**
  * Used on Discovery page loading to get the trending videos.
  */
  public loadTrending() {
    this.videoService.getTrending().subscribe(
      videos => this.trendingVideos = videos,
    );
  }
}
