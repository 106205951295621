import { Component }      from '@angular/core';
import { ContactService }  from './contact.service';

import { ContactForm }  from './contact.form';

@Component({
  selector: 'contact',
  templateUrl: 'contact.template.html',
  styleUrls: ['contact.style.sass'],
})
export class ContactComponent {
  // Form empty values
  public form = new ContactForm;

  public formErrors: any;
  public response: string;
  public status: number = 0;

  constructor(private contactService: ContactService) {}

  public sendForm() {
      // Set status to 1 = Started
    this.contactService.sendForm(this.form).subscribe(
          (response) => {
            this.status = 2;
            this.response = response;
          },
          (error) => {
            if (error.status >= 500) {
              this.status = 0;
              this.formErrors = {
                __all__: [
                  `An unexpected error has occurred!\n
                  Our in-house team have been notified
                  and are working to fix the issue.`,
                ],
              };
            } else {
              this.status = 0;
              this.formErrors = error.json().errors;
            }
          },
      );
  }
}
