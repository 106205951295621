import { Injectable } from '@angular/core';
import { Http, URLSearchParams } from '@angular/http';
import { SearchResponse } from './search.model';
import { environment } from '../../environments/environment';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SearchService {
  public baseUrl: string = `${environment.apiUrl}/api/search/`;
  public latestFilters: string[] = [];
  public category: string;
  constructor(private http: Http) {}

  public searchVideo(
      term: string, pageNumber: number,
      filters?: string[],
      related?: number): Observable<SearchResponse> {
    const params = new URLSearchParams();
    let currentFilters = filters;
    params.set('q', term);
    params.set('page', String(pageNumber));
    if (term === '') {
      params.set('sort_by', 'newest');
    }

    if (currentFilters === undefined) {
      currentFilters = this.latestFilters;
    } else {
      if (currentFilters.length > 0) {
        this.latestFilters = currentFilters;
      }
    }

    // If this method is used to load related videos,
    // reset latestFilters (or they get applied when you click "back to search result")
    if (related === 1) {
      this.latestFilters = [];
    }

    currentFilters.forEach(filter => params.append('selected_facets', filter));

    return this.http.get(this.baseUrl, { search: params }).pipe(map(response => response.json()));
  }

  public changeFilter(term: string, filters: string[]): Observable<SearchResponse> {
    return this.searchVideo(term, 1, filters);
  }

  public discoverFilter(category: string) {
    this.category = category;
  }
}
