import { Injectable }                     from '@angular/core';
import { Headers, Http } from '@angular/http';
import { Cookie }                         from 'ng2-cookies/ng2-cookies';

import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment }        from '../../environments/environment';

@Injectable()
export class UploadService {
  constructor(private http: Http) {}

  public uploadFile(form: any) {
    const formData = new FormData();
    formData.append('name', form.name);
    formData.append('email', form.email);
    formData.append('phone', form.phone);
    formData.append('title', form.title);
    formData.append('description', form.description);
    formData.append('tags', form.tags);
    formData.append('sentiment', form.sentiment);
    formData.append('category', form.category);
    formData.append('file', form.file);

    // Logout service, for some reason the view can't read the csrf cookie,
    // so i send an extra header with the token.
    return this.http.post(
      `${environment.apiUrl}/api/upload/`,
      formData,
      { headers: new Headers({ 'X-CSRFToken': Cookie.get('csrftoken') }) },
    ).pipe(
        map(response => response.json() as string),
        catchError(error => throwError(error)),
    );
  }

}
