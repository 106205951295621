import { NgModule }                 from '@angular/core';
import { FormsModule }              from '@angular/forms';
import { HttpModule }               from '@angular/http';
import { BrowserModule }            from '@angular/platform-browser';
import { RouterModule, Routes }     from '@angular/router';

// Components
import { ContactComponent }          from './components/contact/contact.component';
import { DiscoverComponent }        from './components/discover/discover.component';
import { LandingComponent }         from './components/landing/landing.component';
import { MyVaultComponent }         from './components/my-vault/my-vault.component';
import { SearchComponent }          from './components/search/search.component';
import { SideFiltersComponent }     from './components/side-filters/side-filters.component';
import { SideMenuComponent }        from './components/side-menu/side-menu.component';
import { TermsComponent }           from './components/terms/terms.component';
import { UploadComponent }          from './components/upload/upload.component';
import { UserBarComponent }         from './components/user-bar/user-bar.component';
import { VideoComponent }           from './components/video/video.component';
import { VideoThumbComponent }      from './components/videoThumb/videoThumb.component';
import { WrapperComponent }         from './components/wrapper/wrapper.component';

// Services
import { AuthService }              from './common/services/auth.service';
import { BasketService }            from './common/services/basket.service';
import { ContactService }           from './components/contact/contact.service';
import { SearchService }            from './components/search/search.service';
import { UploadService }            from './components/upload/upload.service';
import { VideoService }             from './components/video/video.service';

// Guards
import { AuthGuard }                from './common/guards/auth.guard';

export { LandingComponent,
  SideMenuComponent,
  SearchComponent,
  DiscoverComponent,
  VideoThumbComponent,
  SideFiltersComponent,
  UserBarComponent,
  MyVaultComponent,
  TermsComponent,
  UploadComponent,
  ContactComponent,
};

const appRoutes: Routes = [
  { path: 'discover/:page', component: DiscoverComponent },
  { path: 'search/:page', component: SearchComponent },
  { path: 'search/:page/:term', component: SearchComponent },
  { path: 'video/:id', component: VideoComponent },
  {
    path: 'my-vault',
    component: MyVaultComponent,
    canActivate: [AuthGuard],
  },
  { path: 'terms', component: TermsComponent },
  { path: 'upload', component: UploadComponent },
  { path: 'contact', component: ContactComponent },
  { path: '**', component: LandingComponent },
];

@NgModule({
  bootstrap: [WrapperComponent, SideMenuComponent],
  declarations: [
    WrapperComponent,
    LandingComponent,
    SideFiltersComponent,
    SideMenuComponent,
    SearchComponent,
    DiscoverComponent,
    VideoComponent,
    VideoThumbComponent,
    UserBarComponent,
    MyVaultComponent,
    TermsComponent,
    UploadComponent,
    ContactComponent,
  ],
  imports: [BrowserModule, HttpModule, FormsModule, RouterModule.forRoot(appRoutes)],
  providers: [
    SearchService, VideoService, AuthService, BasketService,
    UploadService, AuthGuard, ContactService,
  ],
})

export class MainModule {}
