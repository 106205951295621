import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router }                from '@angular/router';

declare let ga: Function;

@Component({
  selector: 'wrapper',
  templateUrl: 'wrapper.template.html',
  styleUrls: ['wrapper.style.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class WrapperComponent implements OnInit {
  public router: Router;
  constructor(public $router: Router) {
    this.router = $router;
  }

  public ngOnInit() {
    this.router.events.subscribe(
        (evt) => {
          if (!(evt instanceof NavigationEnd)) {
            return;
          }
          ga('send', 'pageview', evt.urlAfterRedirects);
          window.scrollTo(0, 0);
        },
    );
  }
}
