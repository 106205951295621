import { Component } from '@angular/core';

import { environment } from '../../environments/environment';
import { interval, timer } from 'rxjs';

@Component({
  selector: 'landing',
  templateUrl: 'landing.template.html',
  styleUrls: ['landing.style.sass'],
})
export class LandingComponent {
  public deployUrl: string;

  constructor() {
    this.deployUrl = environment.deployUrl;
  }

  public ngOnInit() {
    this.resizeCarousel();
    interval(12000).subscribe((x) => {
      this.moveCarousel();
    });
  }

  public resizeCarousel = () => {
    const carousel = document.querySelector('#brands_carousel');
    let carouselElements: NodeListOf<HTMLElement>;
    carouselElements = document.querySelectorAll('#brands_carousel li');
    // Set width of carousel elements
    if (carousel instanceof HTMLElement) {
      carousel.style.width = `${((window.innerWidth - 120) / 10) * carouselElements.length + 80}px`;
    }
    for (const i in carouselElements) {
      if (carouselElements[i] instanceof HTMLElement) {
        carouselElements[i].style.width = `${(window.innerWidth - 120) / 10}px`;
      }
    }
  }

  public moveCarousel = () => {
    const carousel = document.querySelector('#brands_carousel');
    if (carousel instanceof HTMLElement) {
      // Cycle carousel elements
      carousel.style.opacity = '0';
      timer(1000).subscribe((x) => {
        if (carousel instanceof HTMLElement) {
          carousel.style.left = `${(parseInt(carousel.style.left, 10) || 0) -
                                (window.innerWidth - 120)}px`;
          if (parseInt(carousel.style.left, 10) <= 0 - carousel.offsetWidth + 200) {
            carousel.style.left = '0px';
          }
          carousel.style.opacity = '1';
        }
      });
    }
  }
}
