import { Component, OnInit }      from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService }          from './search.service';

import { Video }                  from '../video/video.model';
import { Page, SearchResponse }         from './search.model';

@Component({
  selector: 'search',
  templateUrl: 'search.template.html',
  styleUrls: ['search.style.sass'],
})
export class SearchComponent implements OnInit {
  public videos: Video[];
  public page: Page = new Page();
  public filterActive: boolean = false;
  public filterString: string = '';
  public term: string = '';
  public error: boolean = false;
  public loading: boolean = true;
  constructor(
        private searchService: SearchService,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

  public ngOnInit() {
    this.page.page = 1;
    this.route.params.subscribe((params) => {
      if (this.searchService.category !== undefined) {
        this.filterString = this.searchService.category;
        this.filterActive = true;
        this.searchService.category = undefined;
      } else {
        this.page.page = +params.page;
        this.term = params.term;
        if (params.term === undefined) {
          this.term = '';
        }
        if (this.page.page < 1) {
          this.page.page = 1;
        }
        this.loadVideos(this.term, this.page.page);
      }
    });
  }

  /**
  * Called when we search from the input box
  */
  public searchVideos(term: string) {
    this.videos = [];
    this.loading = true;
    this.router.navigate(['/search', 1, term]);
  }

  public toggleFilterBar() {
    this.filterActive = !this.filterActive;
  }

  /**
  * Called every time the page /search is loaded
  */
  public loadVideos(term: string, page: number) {
    this.searchService.searchVideo(term, page).subscribe(
      this.handleResponse,
      err => this.error = true,
    );
  }

  /**
  * Called on filter change
  */
  public onFilter(filters: string[]) {
    this.videos = [];
    this.loading = true;
    this.searchService.changeFilter(this.term, filters).subscribe(
      this.handleResponse,
      err => this.error = true,
    );
  }

    /**
     * Method to handle the response from
     * the server after a search request.
     *
     * We expect a SearchResponse object to be returned.
     */
  public handleResponse = (resp: SearchResponse) => {
    this.error = false;
    this.loading = false;
    const videos = resp.products;
    const page = resp.page;
    this.videos = videos;
    this.page.has_next = page.has_next;
    this.page.has_previous = page.has_previous;
    this.page.num_pages = page.num_pages;
  }
}
