/* tslint:disable:variable-name */
import { Video } from '../video/video.model';

/**
 * Page component holding the details for pagination.
 */
export class Page {
  public page: number;
  public num_pages: number;
  public has_next: boolean;
  public has_previous: boolean;
}

/**
 * Response object from a search request.
 *
 * We expect to receive information about the page we are on
 * as well as the video details we need.
 */
export class SearchResponse {
  public products: Video[];
  public page: Page;
}
