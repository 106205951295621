export class VideoUploadForm {
  public name: string = '';
  public email: string = '';
  public phone: string = '';

  public title: string = '';
  public description: string = '';
  public tags: string = '';
  public sentiment: string = '';
  public category: string = '';
  public file: File[] = [];
}
