import { Injectable } from '@angular/core';
import { Http }       from '@angular/http';

import { Video }      from './video.model';
import { map }        from 'rxjs/operators';

import { environment }        from '../../environments/environment';

@Injectable()
export class VideoService {
  constructor(private http: Http) {}

  public getVideo(id: number) {
    return this.http.get(`${environment.apiUrl}/api/video/${id}/`)
        .pipe(
            map(response => response.json() as Video),
        );
  }

  public getTrending() {
    return this.http.get(`${environment.apiUrl}/api/trending/`)
        .pipe(
            map(response => response.json() as Video),
        );
  }
}
