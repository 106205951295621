import { Component, OnInit } from '@angular/core';

import { AuthService }       from '../../common/services/auth.service';
import { MyVaultService }    from './my-vault.service';
import { Order }             from './orders.model';

import { AccountForm }  from './myvault.form';

@Component({
  selector: 'my-vault',
  templateUrl: 'my-vault.template.html',
  styleUrls: ['my-vault.style.sass'],
  providers: [MyVaultService],
})
export class MyVaultComponent implements OnInit {
  public orders: Order[] = [];
  public accountForm = new AccountForm;

  public formErrors: any;
  public response: string;
  public status: number = 0;

  constructor(private myVaultService: MyVaultService, private userService: AuthService) {}

  public ngOnInit() {
    this.userService.getUser().subscribe(
      (response) => {
        this.accountForm.email = response['email'];
      },
    );

    this.myVaultService.getOrders().subscribe(data => this.orders = data);
  }

  public updateInfo = () => {
    this.myVaultService.updateInfo(this.accountForm).subscribe(
        (response) => {
          // Set status to 2 = Finished
          this.status = 2;
          this.response = response;
        },
        (error) => {
          if (error.status >= 500) {
            this.status = 0;
            this.formErrors = {
              __all__: [
                `An unexpected error has occurred!\n'
                Our in-house team have been notified
                and are working to fix the issue.`,
              ],
            };
          } else {
            this.status = 0;
            this.formErrors = error.json().errors;
          }
        },
    );
  }

  public sortedOrders(): Order[] {
    return this.orders.sort((a: Order, b: Order): number => {
      if (a.order.number > b.order.number) { return -1; }
      if (a.order.number < b.order.number) { return 1; }
      return 0;
    });
  }
}
