/*
 * Auth guard class to restrict certain routes from logged in users.
 */
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';

/*
 * Guard to make sure a user is logged in.
 * If a route that uses this guard is accessed by a non-logged in user
 * they will be redirected to the discovery page.
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggedIn) {
      return true;
    }

    this.router.navigate(['/discover', '1']);
    return false;
  }
}
