import { Injectable } from '@angular/core';
import { Headers, Http, URLSearchParams } from '@angular/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';

import { Observable, throwError, Subject } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class BasketService {
  public basketUpdated$: Observable<string>;
  public basketSubject: Subject<string>;

  constructor(
    private http: Http,
  ) {
    this.basketSubject = new Subject<string>();
    this.basketUpdated$ = this.basketSubject.asObservable();
  }

  public addToBasket(product: string, licence: number) {
    const params = new URLSearchParams();
    params.set('url', `${environment.apiUrl}/api/products/${product}/`);
    params.set('quantity', '1');
    params.set('currency', 'GBP');
    params.set('licence', String(licence));

    return this.http.post(
      `${environment.apiUrl}/api/basket/add-product-bb/`,
      params,
      { headers: new Headers({ 'X-CSRFToken': Cookie.get('csrftoken'),
        Authorization: `Token ${Cookie.get('auth_token')}` }),
      },
    ).pipe(
      map((response) => {
        this.basketSubject.next(response.json() as string);
        return response.json() as string;
      }),
      catchError(error => throwError(error)),
    );
  }

  public checkout(selectedOrderUser: number, notes: string) {
    const params = new URLSearchParams();
    params.set('billing_address', '');
    params.set('shipping_charge', '0');
    params.set('shipping_address.line1', 'none');
    params.set('shipping_address.country', `${environment.apiUrl}/api/countries/GB/`);
    params.set('order_notes', notes);
    params.set('order_as', String(selectedOrderUser));

    return this.http.get(
      `${environment.apiUrl}/api/basket/`,
      { headers: new Headers({ Authorization: `Token ${Cookie.get('auth_token')}` }) },
    ).pipe(
      switchMap((response) => {
        params.set('basket', `${environment.apiUrl}/api/baskets/${response.json().id}/`);
        return this.http.post(
          `${environment.apiUrl}/api/checkout/`,
          params,
          { headers: new Headers({ 'X-CSRFToken': Cookie.get('csrftoken'),
            Authorization: `Token ${Cookie.get('auth_token')}` }),
          },
        ).pipe(map(response => response.json() as string));
      }),
    );
  }

  public getMyBasketProducts() {
    return this.getMyBasket().pipe(
      switchMap(response => this.http.get(
        `${environment.apiUrl}/api/baskets/${response.json().id}/lines-bb/`,
        { headers: new Headers({ Authorization: `Token ${Cookie.get('auth_token')}` }) },
      ).pipe(map(response => response.json())),
    ),
      catchError(error => throwError(error)),
    );
  }

  public getStripeSession(basketId) {
    return this.http.get(
      `${environment.apiUrl}/api/baskets/${basketId}/stripe-session/`,
      { headers: new Headers({ Authorization: `Token ${Cookie.get('auth_token')}` }) },
    ).pipe(map(response => response.json()));
  }

  public getMyBasket() {
    return this.http.get(
      `${environment.apiUrl}/api/basket/`,
      { headers: new Headers({ Authorization: `Token ${Cookie.get('auth_token')}` }) },
    );
  }

  public removeItemFromCart(id) {
    const params = new URLSearchParams();
    params.set('line', id);
    return this.http.post(
  `${environment.apiUrl}/api/basket/remove-product/`,
  params,
  { headers: new Headers({ Authorization: `Token ${Cookie.get('auth_token')}` }) },
    ).pipe(
        map((response) => {
          if (response.status === 200) {
            return response.json() as string;
          }
        }),
    );
  }
}
