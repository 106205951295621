import { Component }      from '@angular/core';
import { UploadService }  from './upload.service';

import { VideoUploadForm }  from './upload.form';

@Component({
  selector: 'upload',
  templateUrl: 'upload.template.html',
  styleUrls: ['upload.style.sass'],
})
export class UploadComponent {
  // Form empty values
  public form = new VideoUploadForm;

  public formErrors: any;
  public response: string;
  public status: number = 0;

  constructor(private uploadService: UploadService) {}

  public fileChangeEvent(fileInput: any) {
    this.form.file = fileInput.target.files[0] as File[];
  }

  public startUpload() {
      // Set status to 1 = Started
    this.status = 1;
    this.uploadService.uploadFile(this.form).subscribe(
          (response) => {
            // Set status to 2 = Finished
            this.status = 2;
            this.response = response;
          },
          (error) => {
            if (error.status >= 500) {
              this.status = 0;
              this.formErrors = {
                __all__: [
                  `An unexpected error has occurred!\n
                  Our in-house team have been notified
                  and are working to fix the issue.`,
                ],
              };
            } else {
              this.status = 0;
              this.formErrors = error.json().errors;
            }
          },
      );
  }
}
