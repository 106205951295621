import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChange } from '@angular/core';

@Component({
  selector: 'sideFilters',
  templateUrl: 'side-filters.template.html',
  styleUrls: ['side-filters.style.sass'],
})
export class SideFiltersComponent implements OnChanges {
  public facetString: string;
  public filters: Set<string> = new Set<string>();
  @Input() public status: boolean;
  @Input() public filterstring: string;
  @Output() public onFilter = new EventEmitter<string[]>();
  constructor() { }

  public ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes.filterstring &&
        changes.filterstring.previousValue !== changes.filterstring.currentValue
      ) {
      this.toggleFilter('video_categories', changes.filterstring.currentValue);
    }
  }

  public toggleFilter(facet: string, value: string) {
        // Check the current string, to know if you have to remove or add the filter
    if (value !== '') {
      this.facetString = `${facet}_exact:${value}`;
      if (typeof this.filters === 'undefined') {
        this.filters = new Set<string>();
      }
      if (this.filters.has(this.facetString)) {
        this.filters.delete(this.facetString);
      } else {
        this.filters.add(this.facetString);
      }
      if (this.filters.size === 0) {
        this.onFilter.emit([]);
      } else {
        this.onFilter.emit(Array.from(this.filters));
      }
    }
  }
}
