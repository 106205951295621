import { Injectable }              from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { Cookie }                  from 'ng2-cookies/ng2-cookies';

import { environment }        from '../../environments/environment';

import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Order } from './orders.model';

@Injectable()
export class MyVaultService {

  private ordersUrl: string = `${environment.apiUrl}/api/order_history/`;

  constructor(private http: Http) {}

  public updateInfo(form: any) {
    const formData = new FormData();
    formData.append('email', form.email);
    formData.append('old_password', form.old_password);
    formData.append('new_password1', form.new_password1);
    formData.append('new_password2', form.new_password2);

    return this.http.post(
      `${environment.apiUrl}/api/account/`,
      formData,
      {
        headers: new Headers({
          'X-CSRFToken': Cookie.get('csrftoken'),
          Authorization: `Token ${Cookie.get('auth_token')}`,
        }),
      }).pipe(
        map(response => response.json() as string),
        catchError(error => throwError(error)),
      );
  }

  public getOrders() {
    const options = {
      headers: new Headers({
        'X-CSRFToken': Cookie.get('csrftoken'),
        Authorization: `Token ${Cookie.get('auth_token')}`,
      }),
    };

    return this.http.get(this.ordersUrl, options)
      .pipe(
        map((response: Response) => response.json() as Order[]),
        catchError(error => throwError(error)),
      );
  }
}
