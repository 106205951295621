import { Component, Input } from '@angular/core';
import { Video }            from '../video/video.model';

@Component({
  selector: 'videoThumb',
  templateUrl: 'videoThumb.template.html',
  styleUrls: ['videoThumb.style.sass'],
})
export class VideoThumbComponent {
  @Input() public video: Video;
}
