import { Component }  from '@angular/core';
import { Router, NavigationEnd }     from '@angular/router';

import { AuthService }  from '../../common/services/auth.service';

import { environment }        from '../../environments/environment';

@Component({
  selector: 'side-menu',
  templateUrl: 'side-menu.template.html',
  styleUrls: ['side-menu.style.sass'],
})
export class SideMenuComponent {
  public authService: AuthService;
  public router: Router;
  public deployUrl: string;
  public menuOpen: boolean = false;
  constructor(public $router: Router, public $authService: AuthService) {
    this.router = $router;
    this.authService = $authService;
    this.deployUrl = environment.deployUrl;
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { this.menuOpen = false; }
    });
  }

  public toggleMobileMenu() {
    this.menuOpen = !this.menuOpen;
  }
}
