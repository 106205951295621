/*
 * Common service for authentication - mainly used to see if a person is logged in.
 */

import { Injectable } from '@angular/core';
import { Headers, Http, URLSearchParams } from '@angular/http';
import { Router } from '@angular/router';
import { Cookie } from 'ng2-cookies/ng2-cookies';

import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment }        from '../../environments/environment';

@Injectable()
export class AuthService {

  public isLoggedIn: boolean = false;
  constructor(private http: Http, private router: Router) {}

  public loginAndRegister(action: string, firstname: string, username: string, password: string) {
    const params = new URLSearchParams();
    params.set('username', username);
    params.set('password', password);

    let baseUrl;
    if (action === 'register') {
      baseUrl = `${environment.apiUrl}/api/register/`;
      params.set('email', username);
      params.set('firstname', firstname);
    }
    if (action === 'login') {
      baseUrl = `${environment.apiUrl}/api/login_api/`;
    }

    return this.http.post(
        baseUrl,
        params,
        { headers: new Headers({ 'X-CSRFToken': Cookie.get('csrftoken') }) },
      ).pipe(
        map((response) => {
          this.isLoggedIn = true;
          Cookie.set('auth_token', response.json().auth_token as string);
          return response.json() as string;
        }),
        catchError((error) => {
          this.isLoggedIn = false;
          return throwError(error);
        }),
      );
  }

  public forgotPassword(email: string) {
    const formData = new FormData();
    formData.append('email', email);
    return this.http.post(
      `${environment.apiUrl}/api/forgot_password/`,
      formData,
      { headers: new Headers({ 'X-CSRFToken': Cookie.get('csrftoken') }) },
    ).pipe(
      map(response => response.json() as string),
    );
  }

  public logout() {
    // My Vault is limited to logged in users - but won't automatically move out.
    if (this.router.url === '/my-vault') {
      this.router.navigate(['/discover', 1]);
    }
    this.isLoggedIn = false;
    // Logout service, for some reason the view can't read the csrf cookie,
    // so i send an extra header with the token.
    return this.http.delete(
      `${environment.apiUrl}/api/login_api/`,
      { headers: new Headers({
        'X-CSRFToken': Cookie.get('csrftoken'),
        Authorization: `Token ${Cookie.get('auth_token')}`,
      })},
    ).pipe(
      map((response) => {
        Cookie.set('auth_token', '');
        return response.json() as string;
      }),
      catchError(error => throwError(error)),
    );
  }

  public getUser() {
    return this.http.get(
      `${environment.apiUrl}/api/login_api/`,
      { headers: new Headers({ Authorization: `Token ${Cookie.get('auth_token')}` }) },
    ).pipe(
      map((response) => {
        if (response.status === 200) {
          this.isLoggedIn = true;
        }
        return response.json() as string;
      }),
      catchError((error) => {
        this.isLoggedIn = false;
        return throwError(error);
      }),
    );
  }

  public getUserList() {
    return this.http.get(
      `${environment.apiUrl}/api/user_list/`,
      { headers: new Headers({ Authorization: `Token ${Cookie.get('auth_token')}` }) },
    ).pipe(
      map((response) => {
        if (response.status === 200) {
          this.isLoggedIn = true;
        }
        return response.json() as string;
      }),
      catchError((error) => {
        this.isLoggedIn = false;
        return throwError(error);
      }),
    );
  }

}
